import React from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import './Projects.css';

const projects = [
  {
    title: 'Afrisky Weather',
    description: 'A weather app',
    image: 'https://github.com/Kelys3/AfriSky-Weather/blob/master/screenshots/Capture-AfriskyCover.PNG?raw=true',
    link: 'https://github.com/Kelys3/AfriSky-Weather/tree/master',
  },
  {
    title: 'TastyDrive',
    description: 'A food ordering app',
    image: 'https://github.com/Kelys3/TastyDrive/blob/main/Screenshots/welcomepage.png?raw=true',
    link: 'https://github.com/Kelys3/TastyDrive',
  },
  // Add more projects as needed
];

function Projects() {
  return (
    <Container id="projects" className="projects-container">
      <h2 className="my-4 text-center projects-title">My Projects</h2>
      <Row>
        {projects.map((project, index) => (
          <Col key={index} xs={12} md={6} lg={4} className="mb-4">
            <Card className="project-card">
              <Card.Img variant="top" src={project.image} className="project-image" />
              <Card.Body>
                <Card.Title className="project-title">{project.title}</Card.Title>
                <Card.Text className="project-description">{project.description}</Card.Text>
                <a href={project.link} target="_blank" rel="noopener noreferrer" className="btn btn-primary project-link">
                  View Project
                </a>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Projects;
