// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './App.css';
import Home from './components/Home';
import About from './components/About';
import Skills from './components/Skills';
import Hobbies from './components/Hobbies';
import Contact from './components/Contact';
import Projects from './components/Projects';

function ScrollToTop() {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  const [expanded, setExpanded] = useState(false);

  const handleNavLinkClick = () => {
    setExpanded(false);
  };

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Navbar expand="lg" expanded={expanded} className="custom-navbar">
          <Container>
            <Navbar.Brand as={Link} to="/" className="brand-name">Freda Tsorme-Dzebu</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : 'expanded')} />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/" onClick={handleNavLinkClick} className="nav-link">Home</Nav.Link>
                <Nav.Link as={Link} to="/about" onClick={handleNavLinkClick} className="nav-link">About</Nav.Link>
                <Nav.Link as={Link} to="/skills" onClick={handleNavLinkClick} className="nav-link">Skills</Nav.Link>
                <Nav.Link as={Link} to="/hobbies" onClick={handleNavLinkClick} className="nav-link">Hobbies</Nav.Link>
                <Nav.Link as={Link} to="/contact" onClick={handleNavLinkClick} className="nav-link">Contact</Nav.Link>
                <Nav.Link as={Link} to="/projects" onClick={handleNavLinkClick} className="nav-link">Projects</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container>
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/skills" element={<Skills />} />
              <Route path="/hobbies" element={<Hobbies />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/projects" element={<Projects />} />
            </Routes>
          </main>
        </Container>
        <footer className="footer">
          <p>&copy; 2024 Freda Tsorme-Dzebu. All Rights Reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
