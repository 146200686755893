import React from "react";
import PictureFrame from "./PictureFrame";
import './Home.css'; // Import the CSS file for Home component

function Home() {
  return (
    <div className="home-card">
      <h1 className="home-title">Welcome to Freda's Portfolio</h1>
      <p className="home-subtitle">Aspiring Tech Innovator | Backend Developer | Lifelong Learner</p>
      <PictureFrame />
    </div>
  );
}

export default Home;
