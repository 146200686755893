import React from 'react';
import { Card } from 'react-bootstrap';
import './Hobbies.css';

function Hobbies() {
  return (
    <section id="hobbies" className="hobbies-section">
      <Card className="my-4 custom-card">
        <Card.Body>
          <Card.Title className="custom-card-title">Hobbies</Card.Title>
          <Card.Text className="custom-card-text">In my free time, I enjoy watching:</Card.Text>
          <ul className="custom-list">
            <li className="custom-list-item">Baking videos</li>
            <li className="custom-list-item">DIY videos</li>
            <li className="custom-list-item">Lifestyle videos</li>
          </ul>
        </Card.Body>
      </Card>
    </section>
  );
}

export default Hobbies;
