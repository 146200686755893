import React, { useState, useEffect } from 'react';
import './PictureFrame.css';
import Image1 from '../images/picture1.JPG';
import Image2 from '../images/picture2.jpeg';
import Image3 from '../images/picture1.png';
import Image4 from '../images/picture2.png';

const images = [
  Image1,
  Image2,
  Image3,
  Image4,
];

function PictureFrame() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 20000); // Change image every 20,000 milliseconds (20s)

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="picture-frame" onClick={handleClick}>
      <img src={images[currentImageIndex]} alt="Slideshow" className="circular-frame" />
    </div>
  );
}

export default PictureFrame;
