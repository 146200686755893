import React from 'react';
import { Card } from 'react-bootstrap';
import './About.css';

function About() {
  return (
    <section id="about" className="about-section">
      <Card className="my-4 custom-card">
        <Card.Body>
          <Card.Title className="custom-card-title">About Me</Card.Title>
          <Card.Text className="custom-card-text">
            Hello! I'm Freda Tsorme-Dzebu, a recent graduate from ALX Software Engineering program. Passionate about technology and continuous learning, I'm currently seeking opportunities to apply my skills and grow in the field.
          </Card.Text>
          <Card.Text className="custom-card-text">
            My journey in software engineering is just beginning, with a focus on mastering React.js for frontend development and PHP for backend projects. While my background is in human biology, my fascination with technology has led me to explore new horizons.
          </Card.Text>
          <Card.Text className="custom-card-text">
            Beyond coding, I'm a dedicated wife and proud mother of three wonderful kids. Though spare time is a luxury, I find solace in gospel music, particularly from artists like Elevation, Maverick, and Hillsong.
          </Card.Text>
          <Card.Text className="custom-card-text">
            Driven by a desire to make a difference, I aspire to become a tech innovator and advocate for women's empowerment, particularly in Africa. In the vast world of software engineering, I gravitate towards backend development, system administration, and DevOps, prioritizing user experience over UI design.
          </Card.Text>
        </Card.Body>
      </Card>
    </section>
  );
}

export default About;
