import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import './Skills.css'; // Import the CSS file

const techStack = [
  { name: 'JavaScript', url: 'https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png' },
  { name: 'HTML', url: 'https://upload.wikimedia.org/wikipedia/commons/6/61/HTML5_logo_and_wordmark.svg' },
  { name: 'Bootstrap', url: 'https://upload.wikimedia.org/wikipedia/commons/b/b2/Bootstrap_logo.svg' },
  { name: 'CSS', url: 'https://upload.wikimedia.org/wikipedia/commons/d/d5/CSS3_logo_and_wordmark.svg' },
  { name: 'Firebase', url: 'https://www.gstatic.com/mobilesdk/160503_mobilesdk/logo/2x/firebase_28dp.png' },
  { name: 'Firestore', url: 'https://cdn.worldvectorlogo.com/logos/firebase-1.svg' },
  { name: 'Python', url: 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg' },
  { name: 'MongoDB', url: 'https://www.vectorlogo.zone/logos/mongodb/mongodb-ar21.svg' },
  { name: 'MySQL', url: 'https://www.vectorlogo.zone/logos/mysql/mysql-ar21.svg' },
  { name: 'Laravel', url: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Laravel.svg' },
  { name: 'React.js', url: 'https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg' },
  { name: 'Node.js', url: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg' },
  { name: 'Django', url: 'https://static.djangoproject.com/img/logos/django-logo-negative.svg' },
  { name: 'Flask', url: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/Flask_logo.svg' },
  { name: 'Jinja', url: 'https://th.bing.com/th/id/R.d9c9dd71563ee1be60d74902c35366e5?rik=lLjSUO3RXqZZtw&pid=ImgRaw&r=0' },
  { name: 'VSCode', url: 'https://code.visualstudio.com/assets/images/code-stable.png' },
  { name: 'XAMPP', url: 'https://www.pngrepo.com/png/354575/512/xampp.png' },
  { name: 'Git', url: 'https://upload.wikimedia.org/wikipedia/commons/e/e0/Git-logo.svg' },
  { name: 'GitHub', url: 'https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg' },
];

function Skills() {
  return (
    <section id="skills" className="skills-section">
      <Card className="my-4 custom-card">
        <Card.Body>
          <Card.Title className="custom-card-title">Skills</Card.Title>
          <Card.Text className="custom-card-text">I'm currently learning React.js. I'm actually loving it so far. I am building this website with react while learning give me feedback on how I did. My tech stack includes:</Card.Text>
          <Row>
            {techStack.map((tech, index) => (
              <Col key={index} xs={6} md={4} lg={3} className="text-center my-2">
                <img src={tech.url} alt={tech.name} className="tech-logo" />
                <p className="tech-name">{tech.name}</p>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </section>
  );
}

export default Skills;
