import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import './Contact.css';

const socialMedia = [
  { name: 'Email', url: 'mailto:fredatd2016@gmail.com', logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4e/Gmail_Icon.png' },
  { name: 'X', url: 'https://x.com/Keli_016', logo: 'https://th.bing.com/th/id/OIP.OiRP0Wt_nlImTXz5w45aRQHaHa?rs=1&pid=ImgDetMain' },
  { name: 'LinkedIn', url: 'https://www.linkedin.com/in/freda-tsorme-dzebu-1498b0a7/', logo: 'https://www.keesingtechnologies.com/wp-content/uploads/2018/07/Linkedin-Icon.png' },
  { name: 'GitHub', url: 'https://github.com/Kelys3', logo: 'https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg' },
];

function Contact() {
  return (
    <section id="contact" className="contact-section">
      <Card className="my-4 custom-card">
        <Card.Body>
          <Card.Title className="custom-card-title">Contact</Card.Title>
          <Row>
            {socialMedia.map((media, index) => (
              <Col key={index} xs={6} md={3} className="text-center my-2">
                <a href={media.url} target="_blank" rel="noopener noreferrer">
                  <Card className="social-media-card">
                    <Card.Img variant="top" src={media.logo} className="social-media-logo" />
                    <Card.Body>
                      <Card.Text className="social-media-name">{media.name}</Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </section>
  );
}

export default Contact;
